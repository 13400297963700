import { useEffect, useRef, useState } from "react";

import TbankAPI from "../../api/services/t-bank";
import ClinicsAPI from "../../api/services/clinics";
import DoctorsAPI from "../../api/services/doctors";

import CounterpartyEntity from "./CounterpartyEntity";
import CounterpartyIndividual from "./CounterpartyIndividual";

import Radio from "../../atoms/Radio/Radio";

import promptUnSuccessIcon from "./promptUnSuccessIcon.svg";
import promptSuccessIcon from "./promptSuccessIcon.svg";

import "./Counterparty.scss";

const Counterparty = ({ role, id }) => {
  let [section, setSection] = useState("entity");
  let [counterPartyType, setCounterPartyType] = useState("");

  const [promtOpen, setPromtOpen] = useState(false);

  const [paymentEntity, setPaymentEntity] = useState(null);
  const [paymentIndividualCards, setPaymentIndividualCards] = useState(null);

  const getPaymentEntityDetails = async () => {
    const data = await TbankAPI.getTbankEntityDetails({
      role,
      id,
    });
    if (data?.success) {
      setPaymentEntity(data.info);
    }
  };

  const getPaymentIndividualCards = async () => {
    const data = await TbankAPI.getTbankIndividualCards({
      role,
      id,
    });
    if (data?.success) {
      setPaymentIndividualCards(data.cards);
    }
  };

  const getRequisitesStatus = async () => {
    const data =
      role === "clinic"
        ? await ClinicsAPI.getClinicsRequisitesStatus({
            id,
          })
        : await DoctorsAPI.getDoctorsRequisitesStatus({
            id,
          });
    if (data?.success) {
      setCounterPartyType(data.status.counterpartyType);
      setSection(data.status.counterpartyType || "entity");
    }
  };

  useEffect(() => {
    getPaymentEntityDetails();
    getPaymentIndividualCards();
    getRequisitesStatus();
  }, []);

  let promtOpenRef = useRef(null);

  let closePrompt = () => {
    setPromtOpen(false);
  };
  let listener = (event) => {
    // operators list
    if (!promtOpenRef.current) return;
    if (!promtOpenRef.current.contains(event.target)) {
      closePrompt();
    }
  };

  useEffect(() => {
    document.addEventListener("click", listener, false);
    return () => {
      document.removeEventListener("click", listener, false);
    };
  }, []);

  return (
    <div className="Counterparty">
      {role === "doctor" ? (
        <div className="Counterparty__head mb-4">
          <div className="Counterparty__switcher">
            <Radio
              label="ИП / Юридическое лицо"
              name="counterPartyType"
              checked={section === "entity" ? true : false}
              updateChecked={() => {
                setSection("entity");
              }}
            />
            <Radio
              label="Самозанятость"
              name="counterPartyType"
              checked={section === "individual" ? true : false}
              updateChecked={() => setSection("individual")}
            />
          </div>
          <div
            className={`Counterparty__notification ${
              counterPartyType ? "success" : ""
            }`}
          >
            <span>
              {counterPartyType === "entity"
                ? "Пользователь получает деньги на расчетный счёт"
                : counterPartyType === "individual"
                ? "Пользователь получает деньги на карту"
                : "Не выбран способ приема платежей"}
            </span>
            <div
              className={`prompt`}
              ref={promtOpenRef}
              onClick={() => {
                setPromtOpen(true);
              }}
            >
              {counterPartyType ? (
                <img src={promptSuccessIcon} alt="" />
              ) : (
                <img src={promptUnSuccessIcon} alt="" />
              )}
              <div
                className={`${
                  promtOpen ? "d-block" : "d-none"
                } prompt__info position-absolute p-4`}
              >
                <span className="d-block adress">
                  {counterPartyType === "entity"
                    ? 'Чтобы получать деньги на карту, нужно выбрать "Самозанятый", привязать карту и нажать "Включить приём платежей на карту"'
                    : counterPartyType === "individual"
                    ? 'Чтобы получать деньги на расчетный счёт, нужно выбрать "ИП / Юридическое лицо", заполнить реквизиты и нажать "Включить приём платежей на р/с"'
                    : "Выберите организационно-правовую форму, заполните данные или привяжите карты, внизу нажмите кнопку включения приёма платежей"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {section === "entity" && paymentEntity ? (
        <CounterpartyEntity
          role={role}
          id={id}
          formData={paymentEntity}
          isEntity={counterPartyType === "entity"}
          updateInfo={() => {
            getPaymentEntityDetails();
          }}
          updateRequisitesStatus={() => {
            getRequisitesStatus();
          }}
        />
      ) : (
        ""
      )}

      {section === "individual" && paymentIndividualCards ? (
        <CounterpartyIndividual
          role={role}
          id={id}
          dataCards={paymentIndividualCards}
          isIndividual={counterPartyType === "individual"}
          updateInfo={() => {
            getPaymentIndividualCards();
          }}
          updateRequisitesStatus={() => {
            getRequisitesStatus();
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Counterparty;
