import { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

// components
import DateInput from "../../atoms/DateInput/DateInput";

// graphics
import plusIcon from "./plusIcon.svg";
import crossIcon from "./crossIcon.svg";

// styles
import "./InputPlusChecking.scss";

const CustomInput = forwardRef((datePickerProps, ref) => (
  <input type="text" ref={ref} {...datePickerProps} />
));
CustomInput.displayName = "CustomInputRef";

let InputPlusChecking = (props) => {
  /*
	props.label - label
	props.name - input"s name attribute
	props.validation - validation method
	props.errors - validation errors
	props.value - value
	props.currentId
	*/

  // DATA
  const [data, setData] = useState(props.value);

  useEffect(() => {
    setData(props.value);
  }, [props.value]);
  // END OF DATA

  return (
    <>
      {data.map((item, key) => (
        <div
          key={key}
          className={`InputPlusChecking row align-items-center align-content-center ${
            key !== data.length - 1 ? "mb-3" : ""
          }`}
        >
          <label className="InputPlusChecking-group col-12 col-sm-5 col-lg-4 mb-3 mb-sm-0 d-block mb-0">
            <input
              value={item.shop}
              name={props.name + "_" + key}
              required
              data-validation="notEmpty"
              tabIndex="-1"
            />
            <span className="InputPlusChecking-group__label">
              Название магазина
            </span>
          </label>
          <label className="InputPlusChecking-group col-10 col-sm-5 col-lg-4 d-block mb-0">
            <input
              value={item.link}
              name={props.name + "_" + key}
              required
              data-validation="notEmpty"
              tabIndex="-1"
            />
            <span className="InputPlusChecking-group__label">
              Ссылка на магазин
            </span>
          </label>
        </div>
      ))}
    </>
  );
};

export default InputPlusChecking;
