import instance from "../index";

class SubcategoriesAPI {
  static async getSubcategories() {
    let res = await instance.get("/subcategories");
    return await res.data;
  }

  static async createSubcategory(data) {
    let res = await instance.post("/subcategories", data);
    return await res.data;
  }
  static async deleteSubcategory(id, params = {}) {
    let res = await instance.delete("/subcategories/" + id, {
      params,
    });
    return await res.data;
  }
}
export default SubcategoriesAPI;
