import instance from "../index";

class AdminAPI {
  static async logout() {
    let res = await instance.post("/logout/admins");
    return await res.data;
  }
}

export default AdminAPI;
