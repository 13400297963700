import instance from "../index";

class AuthAPI {
  static async login(data) {
    let res = await instance.post("/auth/admins", data);
    return await res.data;
  }
}

export default AuthAPI;
