import { useEffect, useState } from "react";
import { AuthContext } from "./auth-context";
import AdminAPI from "../api/services/admin";

function AuthProvider({ children }) {
  let [isAdmin, setIsAdmin] = useState(null);

  const getMe = async () => {
    if (localStorage.getItem("accessToken")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  let auth = {
    isAdmin: isAdmin,
    signin: (cb) => {
      cb();
      getMe();
    },
    signout: (cb) => {
      setIsAdmin(false);
      localStorage.removeItem("accessToken");
      AdminAPI.logout();
    },
    reconfirm: (cb) => {
      cb();
      getMe();
    },
  };
  if (isAdmin !== null)
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
  else return <div className="main-loader"></div>;
}

export default AuthProvider;
