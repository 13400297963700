import instance from "../index";

class ReviewsAPI {
  static async getReviews(params = {}) {
    let res = await instance.get("/reviews", { params });
    return await res.data;
  }

  static async createReview(data) {
    let res = await instance.post("/reviews", data);
    return await res.data;
  }

  static async editReview(data) {
    let res = await instance.put("/reviews", data);
    return await res.data;
  }

  static async deleteReview(id) {
    let res = await instance.delete("/reviews/" + id);
    return await res.data;
  }
}
export default ReviewsAPI;
