import instance from "../index";

class CategoriesAPI {
  static async getCategories() {
    let res = await instance.get("/categories");
    return await res.data;
  }

  static async createCategory(data) {
    let res = await instance.post("/categories", data);
    return await res.data;
  }

  static async deleteCategory(name) {
    let res = await instance.delete("/categories/" + name);
    return await res.data;
  }
}
export default CategoriesAPI;
