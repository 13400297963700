import { useState } from "react";
import { DateTime } from "luxon";
import { useForm, Controller } from "react-hook-form";
import { PartySuggestions, BankSuggestions } from "react-dadata";
import PhoneInput from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-dadata/dist/react-dadata.css";
import "react-phone-input-2/lib/bootstrap.css";
import SelectWithLabel from "../../atoms/SelectWithLabel/SelectWithLabel";
import ControllerInput from "../../atoms/RHF/ControllerInput";

import TbankAPI from "../../api/services/t-bank";
import GeneralModal from "../../utils/GeneralModal/GeneralModal";

const defaultAddressType = [
  { name: "Юридический", value: "legal" },
  { name: "Фактический", value: "actual" },
  { name: "Почтовый", value: "post" },
  { name: "Прочий", value: "other" },
];

const CounterpartyEntity = ({
  formData,
  updateInfo,
  isEntity,
  updateRequisitesStatus,
  id,
  role,
}) => {
  const [formSubmitButtonIsLoading, setFormSubmitButtonIsLoading] =
    useState(false);

  const [counterPartyButtonIsLoading, setCounterPartyButtonIsLoading] =
    useState(false);

  const [selCountryCode, setSelCountryCode] = useState("ru");
  const [selCountryExpectedLength, setSelCountryExpectedLength] =
    useState(null);
  const [phoneNumber, setPhoneNumber] = useState(formData.ceo.phone || "");
  const [isValid, setIsValid] = useState(true);

  // GENERAL MODAL
  const [generalModalIsOpen, setGeneralModalIsOpen] = useState(false);
  const [generalModalHeader, setGeneralModalHeader] = useState("");
  const [generalModalText, setGeneralModalText] = useState("");
  // GENERAL MODAL

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: formData.fullName || "",
      name: formData.name || "",
      billingDescriptor: formData.billingDescriptor || "",
      inn: formData.inn || "",
      kpp: formData.kpp || "",
      ogrn: formData.ogrn || "",
      addressCountry: formData.address.country || "Россия",
      addressZip: formData.address.zip || "",
      addressCity: formData.address.city || "",
      addressStreet: formData.address.street || "",
      email: formData.email || "",
      ceoFirstName: formData.ceo.firstName || "",
      ceoLastName: formData.ceo.lastName || "",
      ceoMiddleName: formData.ceo.middleName || "",
      ceoBirthDate: formData.ceo.birthDate || "",
      bankName: formData.bankAccount.bankName || "",
      bankBik: formData.bankAccount.bik || "",
      bankAccount: formData.bankAccount.account || "",
    },
  });

  const editFormHandler = async (data) => {
    try {
      data.ceoPhone = "+" + phoneNumber;
      data.inn = data.inn.replace(/\_/g, "");
      data.ogrn = data.ogrn.replace(/\_/g, "");

      data.id = id;
      data.role = role;

      if (data.kpp) data.kpp = data.kpp.replace(/\_/g, "");
      if ((!phoneNumber && selCountryExpectedLength === null) || !isValid) {
        return setIsValid(false);
      }

      setFormSubmitButtonIsLoading(true);

      const response = await TbankAPI.registerTbankEntity(data);
      setFormSubmitButtonIsLoading(false);

      if (response.success) {
        updateInfo();
        setGeneralModalHeader("Успешно");
        setGeneralModalText("Вы успешно добавили данные");
      } else {
        setGeneralModalHeader("Что-то пошло не так");
        setGeneralModalText(response.message);
      }
      setGeneralModalIsOpen(true);
    } catch (error) {
      setGeneralModalHeader("Ошибка");
      setGeneralModalText(
        "Что-то пошло не так. Мы не смогли связаться с сервером, обновите страницу и попробуйте ещё раз."
      );
      setGeneralModalIsOpen(true);
      setFormSubmitButtonIsLoading(false);
    }
  };

  let changeCounterParty = async () => {
    setCounterPartyButtonIsLoading(true);
    const data = await TbankAPI.changeTbankCounterParty({
      type: "entity",
      role,
      id,
    });
    setCounterPartyButtonIsLoading(false);
    if (data) {
      if (data.success) {
        updateRequisitesStatus();
      } else {
        setGeneralModalHeader("Что-то пошло не так");
        setGeneralModalText(data.message);
        setGeneralModalIsOpen(true);
      }
    } else {
      setGeneralModalHeader("Ошибка");
      setGeneralModalText(
        "Что-то пошло не так. Мы не смогли связаться с сервером, обновите страницу и попробуйте ещё раз."
      );
      setGeneralModalIsOpen(true);
    }
  };

  const updateMainInput = (e) => {
    setValue("name", e.value || "");

    const data = e.data;

    setValue("fullName", data.name?.full_with_opf || "");
    setValue("inn", data.inn || "");
    setValue("kpp", data.kpp || "");
    setValue("ogrn", data.ogrn || "");
    setValue("addressCountry", data.address?.data?.country || "Россия");
    setValue("addressZip", data.address.data.postal_code || "");
    setValue("addressCity", data.address.data.city || "");
    setValue(
      "addressStreet",
      data.address
        ? `${data.address?.data?.street_with_type || ""}, ${
            data.address?.data?.house_type || ""
          } ${data.address?.data?.house || ""} ${
            data.address?.data?.block_type || ""
          } ${data.address?.data?.block || ""}`
            .trim()
            .replace(/\s+/g, " ")
        : ""
    );
    const fio = data.management ? data.management.name.split(" ") : [];
    setValue("ceoFirstName", fio[1] || "");
    setValue("ceoLastName", fio[0] || "");
    setValue("ceoMiddleName", fio[2] || "");

    if (data.state?.registration_date) {
      const dt = DateTime.fromMillis(data.state?.registration_date);
      setValue("ceoBirthDate", dt.toFormat("yyyy-MM-dd"));
    }
  };

  const updateBankInput = (e) => {
    setValue("bankName", e.value || "");
    const data = e.data;
    setValue("bankBik", data.bic || "");
  };

  // PHONE INPUT
  let phoneInputOnChange = (inputPhone, countryData) => {
    if (countryData.countryCode !== selCountryCode) {
      setPhoneNumber(countryData.dialCode);
      setIsValid(true);
    } else setPhoneNumber(inputPhone);
    setSelCountryCode(countryData.countryCode);
    setSelCountryExpectedLength(countryData.format.match(/\./g).length);
  };
  // END OF PHONE INPUT

  return (
    <>
      <form className="CounterpartyEntity">
        <div className="row mb-7">
          <div className="col-12 row">
            <div className="col-12 col-lg-6 mb-4">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <PartySuggestions
                    containerClassName={`InputDadata d-block ${
                      errors && errors["name"] ? "error" : ""
                    }`}
                    inputProps={{
                      ...field,
                      placeholder: "Наименование организации *",
                    }}
                    token="f49286aa2635ffaeae3a1d0416183a55c8cab726"
                    defaultQuery={formData.name || ""}
                    onChange={(data) => {
                      field.onChange(data.value);
                      updateMainInput(data);
                    }}
                  />
                )}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="fullName"
                control={control}
                errors={errors}
                rules={{ required: true }}
                label="Полное наименование организации *"
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="billingDescriptor"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z0-9.\-_ ]+$/,
                }}
                label="Наименование латиницей *"
                errors={errors}
              />
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="inn"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    const cleanedValue = value.replace(/_/g, "");

                    const isValid = /^[0-9]{10}$|^[0-9]{12}$/.test(
                      cleanedValue
                    );

                    return isValid;
                  },
                }}
                label="ИНН *"
                errors={errors}
                mask="999999999999"
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="kpp"
                control={control}
                rules={{
                  required: false,
                  validate: (value) => {
                    const isValid = value ? /[0-9]{9}/.test(value) : true;

                    return isValid;
                  },
                }}
                label="КПП"
                errors={errors}
                mask="999999999"
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="ogrn"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    const cleanedValue = value.replace(/_/g, "");

                    const isValid = /^[0-9]{13}$|^[0-9]{15}$/.test(
                      cleanedValue
                    );

                    return isValid;
                  },
                }}
                label="ОГРН / ОГРНИП *"
                errors={errors}
                mask="999999999999999"
              />
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <Controller
                name="addressType"
                control={control}
                defaultValue={defaultAddressType[0].value}
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectWithLabel
                    name={field.name}
                    label="Тип адреса организации *"
                    variants={defaultAddressType}
                    defaultVariant={0}
                    clear={field.value === ""}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4 d-none">
              <ControllerInput
                name="addressCountry"
                control={control}
                rules={{
                  required: false,
                }}
                label="Страна"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="addressZip"
                control={control}
                rules={{
                  required: true,
                  pattern: /[0-9]{6}/,
                }}
                label="Почтовый индекс *"
                errors={errors}
                mask="999999"
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="addressCity"
                control={control}
                rules={{
                  required: true,
                }}
                label="Город или населенный пункт *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="addressStreet"
                control={control}
                rules={{
                  required: true,
                }}
                label="Улица, дом *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="email"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[-A-z0-9.+]+@([A-z0-9][-A-z0-9]+.)+[A-z]{2,4}$/,
                }}
                label="Email *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="ceoFirstName"
                control={control}
                rules={{
                  required: true,
                }}
                label="Имя *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="ceoLastName"
                control={control}
                rules={{
                  required: true,
                }}
                label="Фамилия *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="ceoMiddleName"
                control={control}
                rules={{
                  required: true,
                }}
                label="Отчество *"
                errors={errors}
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <PhoneInput
                country={"ru"}
                localization={ru}
                placeholder="Номер телефона *"
                value={phoneNumber}
                preferredCountries={["ru", "us"]}
                onChange={phoneInputOnChange}
                onBlur={() =>
                  phoneNumber.length !== selCountryExpectedLength
                    ? setIsValid(false)
                    : setIsValid(true)
                }
                isValid={() =>
                  !isValid
                    ? phoneNumber.length === selCountryExpectedLength
                    : isValid
                }
                required
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="ceoBirthDate"
                control={control}
                rules={{
                  required: true,
                }}
                label="Дата регистрации ИП/Юр. лица *"
                errors={errors}
                mask="9999-99-99"
              />
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <Controller
                name="bankName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <BankSuggestions
                    containerClassName={`InputDadata d-block ${
                      errors && errors["bankName"] ? "error" : ""
                    }`}
                    inputProps={{
                      ...field,
                      placeholder: "Наименование банка *",
                    }}
                    token="f49286aa2635ffaeae3a1d0416183a55c8cab726"
                    defaultQuery={formData.bankAccount.bankName || ""}
                    onChange={(data) => {
                      field.onChange(data.value);
                      updateBankInput(data);
                    }}
                  />
                )}
              />
            </div>

            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="bankBik"
                control={control}
                rules={{
                  required: true,
                  pattern: /[0-9]{9}/,
                }}
                label="БИК *"
                errors={errors}
                mask="999999999"
              />
            </div>
            <div className="col-12 col-lg-6 mb-4">
              <ControllerInput
                name="bankAccount"
                control={control}
                rules={{
                  required: true,
                  pattern: /^\d{20}$/,
                }}
                label="Расчетный счет *"
                errors={errors}
                mask="99999999999999999999"
              />
            </div>
            <div className="col-12 mt-3">
              <div className="CounterpartyEntity__buttons">
                <button
                  type="button"
                  onClick={handleSubmit(editFormHandler)}
                  className="secondary-button px-6 "
                  disabled={formSubmitButtonIsLoading}
                >
                  <div
                    className={
                      !formSubmitButtonIsLoading ? "d-none" : "mini-loader"
                    }
                  ></div>
                  <span
                    className={formSubmitButtonIsLoading ? "invisible" : ""}
                  >
                    {`Сохранить${role === "doctor" ? " и проверить" : ""}`}
                  </span>
                </button>
                {role === "doctor" ? (
                  <button
                    type="button"
                    className="secondary-button px-4"
                    disabled={
                      counterPartyButtonIsLoading ||
                      !formData.bankAccount.account ||
                      isEntity
                    }
                    onClick={changeCounterParty}
                  >
                    Включить приём платежей на р/с
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <GeneralModal
        modalIsOpen={generalModalIsOpen}
        modalIsOpenCallback={(state) => setGeneralModalIsOpen(state)}
        modalHeader={generalModalHeader}
        modalText={generalModalText}
      />
    </>
  );
};

export default CounterpartyEntity;
