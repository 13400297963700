import { useEffect, useState } from "react";

import TbankAPI from "../../api/services/t-bank";

import GeneralModal from "../../utils/GeneralModal/GeneralModal";
import ContentModal from "../../utils/ContentModal/ContentModal";

import crossIcon from "./crossIcon.svg";

import "./Counterparty.scss";

const CounterpartyIndividual = ({
  dataCards,
  updateInfo,
  isIndividual,
  updateRequisitesStatus,
  role,
  id,
}) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(dataCards);
  }, [dataCards]);

  const [counterPartyButtonIsLoading, setCounterPartyButtonIsLoading] =
    useState(false);

  // GENERAL MODAL
  const [generalModalIsOpen, setGeneralModalIsOpen] = useState(false);
  const [generalModalHeader, setGeneralModalHeader] = useState("");
  const [generalModalText, setGeneralModalText] = useState("");
  // GENERAL MODAL

  let changeCounterParty = async () => {
    setCounterPartyButtonIsLoading(true);
    const data = await TbankAPI.changeTbankCounterParty({
      type: "individual",
      role,
      id,
    });
    setCounterPartyButtonIsLoading(false);
    if (data) {
      if (data.success) {
        updateRequisitesStatus();
      } else {
        setGeneralModalHeader("Что-то пошло не так");
        setGeneralModalText(data.message);
        setGeneralModalIsOpen(true);
      }
    } else {
      setGeneralModalHeader("Ошибка");
      setGeneralModalText(
        "Что-то пошло не так. Мы не смогли связаться с сервером, обновите страницу и попробуйте ещё раз."
      );
      setGeneralModalIsOpen(true);
    }
  };

  return (
    <div className="CounterpartyIndividual">
      <div className="row">
        {cards ? (
          cards.length ? (
            cards.map((card) => (
              <div className="col-12 col-md-6 mb-3">
                <Card
                  {...card}
                  role={role}
                  userId={id}
                  updateInfo={updateInfo}
                  updateRequisitesStatus={updateRequisitesStatus}
                />
              </div>
            ))
          ) : (
            <span className="d-block col-12">
              На данный момент нет добавленных карт
            </span>
          )
        ) : (
          ""
        )}
      </div>

      <div className="d-flex justify-content-center mt-4">
        <button
          type="button"
          className="secondary-button px-4"
          disabled={
            counterPartyButtonIsLoading ||
            !cards ||
            !cards.length ||
            isIndividual
          }
          onClick={changeCounterParty}
        >
          Включить приём платежей на карту
        </button>
      </div>

      <GeneralModal
        modalIsOpen={generalModalIsOpen}
        modalIsOpenCallback={(state) => setGeneralModalIsOpen(state)}
        modalHeader={generalModalHeader}
        modalText={generalModalText}
      />
    </div>
  );
};

const Card = ({
  id: cardId,
  number,
  active,
  updateInfo,
  updateRequisitesStatus,
  role,
  userId,
}) => {
  const [formSubmitButtonIsLoading, setFormSubmitButtonIsLoading] =
    useState(false);

  // GENERAL MODAL
  const [generalModalIsOpen, setGeneralModalIsOpen] = useState(false);
  const [generalModalHeader, setGeneralModalHeader] = useState("");
  const [generalModalText, setGeneralModalText] = useState("");
  // GENERAL MODAL

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const deleteCard = async () => {
    setFormSubmitButtonIsLoading(true);
    const data = await TbankAPI.deleteTbankIndividualCard(cardId, {
      role,
      id: userId,
    });
    setFormSubmitButtonIsLoading(false);
    setDeleteModalIsOpen(false);
    if (data) {
      if (data.success) {
        updateInfo();
        if (data.isLastCard) updateRequisitesStatus();
      } else {
        setGeneralModalHeader("Ошибка");
        setGeneralModalText(data.message);
        setGeneralModalIsOpen(true);
      }
    } else {
      setGeneralModalHeader("Ошибка");
      setGeneralModalText(
        "Что-то пошло не так. Мы не смогли связаться с сервером, обновите страницу и попробуйте ещё раз."
      );
      setGeneralModalIsOpen(true);
    }
  };

  const activeCard = async () => {
    const data = await TbankAPI.activeTbankIndividualCard({
      role,
      cardId,
      id: userId,
    });
    setDeleteModalIsOpen(false);
    if (data) {
      if (data.success) {
        updateInfo(false);
      } else {
        setGeneralModalHeader("Ошибка");
        setGeneralModalText(data.message);
        setGeneralModalIsOpen(true);
      }
    } else {
      setGeneralModalHeader("Ошибка");
      setGeneralModalText(
        "Что-то пошло не так. Мы не смогли связаться с сервером, обновите страницу и попробуйте ещё раз."
      );
      setGeneralModalIsOpen(true);
    }
  };

  return (
    <>
      <div
        className={`card ${active ? "active" : ""}`}
        onClick={() => {
          if (!active) activeCard(cardId);
        }}
      >
        <div className="card__body">
          <span className="card__label mb-1">Номер карты</span>
          <div className="card__number">{number}</div>

          <button
            type="button"
            className="mt-1"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModalIsOpen(true);
            }}
          >
            <img src={crossIcon} />
            <span>Удалить</span>
          </button>

          <span className={`main-method ${active ? "active" : ""}`}>
            Основной метод
          </span>
        </div>
      </div>
      <ContentModal
        modalIsOpen={deleteModalIsOpen}
        modalIsOpenCallback={(state) => setDeleteModalIsOpen(state)}
        modalHeader="Удаление привязанной карты"
      >
        <div className="info">
          <p className="mb-4">
            Вы уверены, что хотите удалить привязанную карту?
          </p>
          <button
            className="secondary-button d-inline-block w-100"
            onClick={() => deleteCard()}
            disabled={formSubmitButtonIsLoading}
          >
            <div
              className={!formSubmitButtonIsLoading ? "d-none" : "mini-loader"}
            ></div>
            <span className={formSubmitButtonIsLoading ? "invisible" : ""}>
              Удалить
            </span>
          </button>
        </div>
      </ContentModal>
      <GeneralModal
        modalIsOpen={generalModalIsOpen}
        modalIsOpenCallback={(state) => setGeneralModalIsOpen(state)}
        modalHeader={generalModalHeader}
        modalText={generalModalText}
      />
    </>
  );
};

export default CounterpartyIndividual;
