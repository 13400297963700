import instance from "../index";

class FarmsAPI {
  static async getFarm(id) {
    let res = await instance.get("/farms/" + id);
    return await res.data;
  }

  static async editFarm(data) {
    let res = await instance.put("/farms", data);
    return await res.data;
  }

  static async createFarm(data) {
    let res = await instance.post("/farms", data);
    return await res.data;
  }

  static async getFarmsArticle(id) {
    let res = await instance.get("/farms/articles/" + id);
    return await res.data;
  }
  static async getFarmsBanner(id) {
    let res = await instance.get("/farms/banners/" + id);
    return await res.data;
  }
  static async getFarmsProduct(id) {
    let res = await instance.get("/farms/products/" + id);
    return await res.data;
  }
  static async editFarmsArticle(data) {
    let res = await instance.put("/farms/articles", data);
    return await res.data;
  }
  static async editFarmsBanner(data) {
    let res = await instance.put("/farms/banners/", data);
    return await res.data;
  }
  static async editFarmsProduct(data) {
    let res = await instance.put("/farms/products/", data);
    return await res.data;
  }
}
export default FarmsAPI;
