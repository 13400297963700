import instance from "../index";

class DoctorsAPI {
  static async getDoctor(id) {
    let res = await instance.get("/doctors/" + id);
    return await res.data;
  }
  static async getDoctors() {
    let res = await instance.get("/doctors");
    return await res.data;
  }
  static async editDoctor(data) {
    let res = await instance.put("/doctors", data);
    return await res.data;
  }

  static async getDoctorsRequisitesStatus(params = {}) {
    let res = await instance.get("/doctors/requisites/status", {
      params,
    });
    return await res.data;
  }
}
export default DoctorsAPI;
