import "./Radio.scss";

const Radio = (props) => {
  let updateRadio = (e) => {
    if (!props.checked) {
      props.updateChecked && props.updateChecked();
    }
  };

  return (
    <label className="Radio d-flex align-items-center">
      <input
        type="radio"
        checked={props.checked}
        data-validation="radioChecked"
        name={props.name}
        onChange={updateRadio}
      />
      <div className={`checkRadio`} onClick={updateRadio}></div>
      <span className="ml-2">{props.label}</span>
    </label>
  );
};

export default Radio;
