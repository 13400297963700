import instance from "../index";

class TbankAPI {
  static async getTbankEntityDetails(params = {}) {
    let res = await instance.get("/t-bank/entity/details", {
      params,
    });
    return await res.data;
  }
  static async getTbankIndividualCards(params = {}) {
    let res = await instance.get("/t-bank/individual/cards", {
      params,
    });
    return await res.data;
  }
  static async registerTbankEntity(data = {}) {
    let res = await instance.post("/t-bank/register/entity", data);
    return await res.data;
  }

  static async activeTbankIndividualCard(data = {}) {
    let res = await instance.post("/t-bank/active/individual/card", data);
    return await res.data;
  }

  static async deleteTbankIndividualCard(id, data = {}) {
    let res = await instance.delete("/t-bank/delete/individual/card/" + id, {
      params: data,
    });
    return await res.data;
  }

  static async changeTbankCounterParty(data = {}) {
    let res = await instance.post("/t-bank/change/counterparty", data);
    return await res.data;
  }
}

export default TbankAPI;
