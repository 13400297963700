import InputMask from "react-input-mask";

// STYLES
import "./InputRHF.scss";

let InputRHF = ({
  availableSymbols,
  name,
  maxLength,
  minLength,
  type,
  disabled,
  mask,
  maskChar,
  label,
  errors,
  ...other
}) => {
  let onPressKeyboard = (e) => {
    if (availableSymbols && availableSymbols === "numbers") {
      if (!/[0-9]/.test(e.key)) e.preventDefault();
    }
  };

  // END OF ERRORS SETTER
  return (
    <label className="InputRHF w-100">
      <InputMask
        className={errors && errors.hasOwnProperty(name) && name ? "error" : ""}
        name={name}
        maxLength={maxLength}
        minLength={minLength}
        type={type || "text"}
        disabled={disabled}
        mask={mask}
        maskChar={maskChar}
        {...other}
        onKeyPress={(e) => {
          onPressKeyboard(e);
        }}
        {...other}
      />
      <span className="Input__label">{label}</span>
    </label>
  );
};

export default InputRHF;
