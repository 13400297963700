import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import AuthAPI from "../api/services/auth";

import { useAuth } from "../auth/use-auth";

//components
import Input from "../atoms/Input/Input";

import "./Auth.scss";

let Auth = () => {
  const authAdminForm = useRef(null);
  let auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  let authAdminFormHandler = async (e) => {
    e.preventDefault();
    let postData = new FormData(authAdminForm.current);
    const data = await AuthAPI.login(postData);
    if (data?.success)
      auth.signin(() => {
        localStorage.setItem("accessToken", data.accessToken);
        navigate("/applications");
      });
    else {
      setError(data?.message || "");
    }
  };

  return (
    <div className="Auth">
      <div className="container-fluid">
        <div className="row px-2 py-3 p-md-5 justify-content-center">
          <form
            onSubmit={authAdminFormHandler}
            ref={authAdminForm}
            className="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-4 p-4"
          >
            <h1 className="mb-4">Вход</h1>
            <div className="mb-4 w-100">
              <Input name="login" label="Логин" />
            </div>
            <div className="w-100">
              <Input name="password" type="password" label="Пароль" />
            </div>
            {error && (
              <span className="text-danger d-inline-block mt-1 w-100">
                {error}
              </span>
            )}
            <button type="submit" className="secondary-button mt-4">
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
