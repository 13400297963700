import instance from "../index";

class PostsAPI {
  static async getPosts() {
    let res = await instance.get("/posts");
    return await res.data;
  }

  static async getPost(id) {
    let res = await instance.get("/posts/" + id);
    return await res.data;
  }

  static async editPost(data) {
    let res = await instance.put("/posts", data);
    return await res.data;
  }

  static async createPost(data) {
    let res = await instance.post("/posts", data);
    return await res.data;
  }

  static async deletePost(id) {
    let res = await instance.delete("/posts/" + id);
    return await res.data;
  }
}
export default PostsAPI;
