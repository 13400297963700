import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.scss";

// components
import Auth from "./pages/Auth";
import Applications from "./pages/Applications";
import Checking from "./pages/Checking";
import Patients from "./pages/Patients";
import Blog from "./pages/Blog";
import Reviews from "./pages/Reviews";
import Settings from "./pages/Settings";
import EditDoctor from "./pages/EditDoctor";
import EditClinic from "./pages/EditClinic";
import EditPost from "./pages/EditPost";
import AddPost from "./pages/AddPost";
import NewSeller from "./pages/NewSeller";
import EditFarm from "./pages/EditFarm";
import EditArticle from "./pages/EditArticle";
import EditProduct from "./pages/EditProduct";
import EditBanner from "./pages/EditBanner";

import EditReviews from "./pages/EditReviews";

import AuthProvider from "./auth/auth-provider";
import AuthRouteGuard from "./auth/auth-route-guard";

let App = () => {
  return (
    <Router>
      <div className="App">
        <AuthProvider>
          <Routes>
            <Route exact path="/" element={<Auth />} />
            <Route element={<AuthRouteGuard />}>
              <Route path="/applications" element={<Applications />} />
              <Route path="/checking" element={<Checking />} />
              <Route path="/sellers/new" element={<NewSeller />} />
              <Route path="/editfarm/:idSeller" element={<EditFarm />} />
              <Route path="/patients" element={<Patients />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/editreviews" element={<EditReviews />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/editdoctor/:idSeller" element={<EditDoctor />} />
              <Route path="/editclinic/:idSeller" element={<EditClinic />} />
              <Route path="/editpost/:idPost" element={<EditPost />} />
              <Route path="/editarticle/:idArticle" element={<EditArticle />} />
              <Route path="/editproduct/:idProduct" element={<EditProduct />} />
              <Route path="/editbanner/:idBanner" element={<EditBanner />} />
              <Route path="/addpost" element={<AddPost />} />
            </Route>
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
};

export default App;
