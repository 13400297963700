import { Controller } from "react-hook-form";
import InputRHF from "./InputRHF";

let ControllerInput = ({ name, control, label, rules = {}, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <InputRHF {...field} label={label} {...other} />}
    />
  );
};

export default ControllerInput;
