import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { useAuth } from "./use-auth";

function AuthRouteGuard() {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.isAdmin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <Outlet />;
}

export default AuthRouteGuard;
