import { useState, useEffect } from "react";
import PostsAPI from "../api/services/posts";
import { Link } from "react-router-dom";

//components
import Navigation from "../blocks/Navigation/Navigation";
import PostCard from "../blocks/PostCard/PostCard";
import BigLoadingState from "../elements/BigLoadingState/BigLoadingState";

// styles
import "./Blog.scss";

let Blog = (props) => {
  const [posts, setPosts] = useState(null);

  // ON PAGE LOAD
  useEffect(() => {
    const getData = async () => {
      let data = await PostsAPI.getPosts("/posts");
      if (data?.success) {
        setPosts(data.posts);
      }
    };
    getData();
  }, []);
  // END OF ON PAGE LOAD

  return (
    <>
      <Navigation page="blog" />
      <div className="Blog">
        <div className="container-fluid">
          <div className="row px-2 py-3 p-md-5">
            {posts ? (
              <>
                <div className="col-12 mb-5 d-flex align-items-center justify-content-between">
                  <h1>Блог</h1>
                  <Link to="/addpost" className="secondary-button">
                    Добавить новую статью
                  </Link>
                </div>
                {posts.map((item, key) => (
                  <div className="col-12 col-lg-6 pb-4" key={key}>
                    <PostCard info={item} />
                  </div>
                ))}
              </>
            ) : (
              <BigLoadingState text="Загружаем посты" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
